<template>
	<layoutWide>
		<sub-navbar page="Transaction" />
		<div>Collections from Customers</div>
		<section>
			<div class="block">
				<b-radio v-model="radio" name="name" native-value="Collect">
					Collect
				</b-radio>
				<b-radio v-model="radio" name="name" native-value="Collect in Advance">
					Collect in Advance
				</b-radio>
				<b-radio v-model="radio" name="name" native-value="Setoff">
					Setoff
				</b-radio>
			</div>
			<p class="content">
				<b>Selection:</b>
				{{ radio }}
			</p>
		</section>
	</layoutWide>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import { ref } from '@vue/composition-api'
export default {
	name: 'CollectionsFromCustomers',
	components: {
		SubNavbar,
	},
	setup() {
		const radio = ref('Collect')
		return {
			radio,
		}
	},
}
</script>

<style lang="scss" scoped></style>
